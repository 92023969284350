<template>
  <div>
    <div v-if="dataLoaded">
      <v-container class="pa-0">
        <!--app-view-toolbar-->
        <app-view-toolbar :title="headline" :showBackButton="true" :buttons="toolbarButtons" @onButton="onToolbarButton" :emitOnBackButton="true" @onBackButton="onBackButton"></app-view-toolbar>
      </v-container>
      <v-container class="py-2 responsive-container-class">
        <v-form
          ref="form"
          lazy-validation
        >
          <app-order-rows
            :mode="orderRowMode"
            :searchableProduct_items="searchableProduct_items"
            :searchableProduct_labelCols="searchableProduct_labelCols"
            :orderRows="viewData.orderRows"
            :orderRowIdToFocus="orderRowIdToFocus"
            :priceTypeIsNetto="viewData.priceTypeIsNetto"
            @addOrderRowText="onAddTextRow"
            @onProductSelect="onProductSelect"
            @removeOrderRow="removeOrderRow"
            @unremoveOrderRow="unremoveOrderRow"
            :showDeliveredQuantity="true"
            :isUndelivered="viewData.isUndelivered"
            :showOptionColumn="viewData.showOptionColumn"
          >
          </app-order-rows>
        </v-form>
        <v-btn
          v-if="viewData.isRestOrder"
          @click="showCancelRestOrder = true"
          color="error"
          small
        >
          <v-icon>mdi-trash-can-outline</v-icon>
          Makulera restorder
        </v-btn>
      </v-container>
    </div>
    <!--confirm for leaving without saving-->
    <app-confirm :show="showLeaveWithoutSaving" title="Lämna utan att spara?" @onYes="doLeave" @onNo="showLeaveWithoutSaving = false"></app-confirm>
    <!--confirm for cancelling rest order-->
    <app-confirm :show="showCancelRestOrder" title="Makulera restordern?" @onYes="doCancelRestOrder" @onNo="showCancelRestOrder = false"></app-confirm>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import useCore from '@/helpers/core'
import { appBus } from '@/main'
// import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
import OrderRows from '@/components/OrderRows.vue'
import ViewToolbar from '@/components/ViewToolbar.vue'
import Confirm from '@/components/Confirm.vue'

export default {
  name: 'OrderRowsEdit',
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-order-rows': OrderRows,
    'app-confirm': Confirm,
  },
  props: {
    ohdId: {
      type: Number,
      required: true,
    },
  },
  // computed: {
  //   containerHorizontalPaddingClass: () => {
  //     console.log('this.$vuetify.breakpoint.smAndUp', this.$vuetify.breakpoint.smAndUp);
  //     return 'px-2';
  //     // return this.$vuetify.breakpoint.mdAndUp ? 'px-6' : 'px-2';
  //   }
  // },
  // data: () => {
  // },
  setup({ ohdId }) {
    // Vue 2
    const store = require('@/store/store').default;
    const router = require('@/router/router').default;

    const { callApi, showMessage, tellKobra, parseMessages, showAlert } = useCore();

    const form = ref(null);
    const orderRowIdToFocus = ref(0);

    const saveInProgress = ref(false);
    // const orderPart = ref(null);
    // const activeOrderPartIndex = ref(-1);
    const showLeaveWithoutSaving = ref(false);
    const showCancelRestOrder = ref(false);

    const viewData = reactive({
      // orderParts: [],
      orderRows: [],
      priceTypeIsNetto: null,
      hasFortnox: null,
      cgrId: 0,
      fortnoxOrderNumber: null,
      isUndelivered: null,
      isRestOrder: null,
      orderEditHash: null,
      showOptionColumn: null,
    });

    const searchableProduct_labelCols = ref([]);
    const searchableProduct_items = ref([]);

    const dataLoaded = ref(false);
        
    const toolbarButtons = computed(() => {
      const buttons = [];
      if (dataLoaded.value) {
        buttons.push({
          label: 'update-order',
          icon: 'check',
          name: 'Uppdatera',
          // disabled: !activeOrderPart.value || saveInProgress.value || !orderPart
          disabled: saveInProgress.value || !viewData.orderRows.length,
        });
      }
      // console.log('computed toolbarButtons', buttons);
      return buttons;
    });

    // const activeOrderPart = computed(() => {
    //   if (activeOrderPartIndex.value === -1) {
    //     return null;
    //   }
    //   return viewData.orderParts[activeOrderPartIndex.value];
    // });

    // const allowOrderRowAdd = computed(() => {
    //   return viewData.orderParts.length && activeOrderPartIndex.value + 1 === viewData.orderParts.length;
    // });

    // const allowPriceEditOfExistingRows = computed(() => {
    //   // price and discount are only editable if there is no rest orders and the order has not been delivered
    //   return activeOrderPart.value && !activeOrderPart.value.isDelivered && viewData.orderParts.length === 1;
    // });

    const orderRowMode = computed(() => {
      return viewData.hasFortnox ? 'edit-fortnox' : 'edit';
    });

    const headline = computed(() => {
      let text = `Weborder: ${ohdId}`
      if (viewData.hasFortnox) {
        text += ` / Fortnox: ${viewData.fortnoxOrderNumber}`;
        if (viewData.isRestOrder) {
          text += ` (restorder)`;
        }
      }
      return text;
    });

    const onBackButton = () => {
      showLeaveWithoutSaving.value = true;
    }
    
    const doLeave = () => {
      // if (activeOrderPart.value && viewData.orderParts.length > 1) {
        // show list of orderParts again
        // activeOrderPartIndex.value = -1;
      // } else {
        setOverlay(true);
        showLeaveWithoutSaving.value = false;
        router.back();
      // }
    }

    const onToolbarButton = button => {
      switch (button.label) {
        case 'update-order':
          updateOrder();
        break;
      }
    }

    const setOverlay = (show = false, message = null) => {
      if (show) {
        saveInProgress.value = true;
        appBus.$emit('overlay', { message });
      } else {
        saveInProgress.value = false;
        appBus.$emit('overlay');
      }
    }

    const removeOrderRow = orderRow => {
      if (orderRow.row_id < 0) {
        // new row, can be removed right away
        // activeOrderPart.value.orderRows = activeOrderPart.value.orderRows.filter(item => item.row_id !== orderRow.row_id);
        viewData.orderRows = viewData.orderRows.filter(item => item.row_id !== orderRow.row_id);
      } else {
        // existing row - just mark it to be removed
        // activeOrderPart.value.orderRows = activeOrderPart.value.orderRows.map(item => {
        viewData.orderRows = viewData.orderRows.map(item => {
          if (item.row_id === orderRow.row_id) {
            item.removeMe = true;
          }
          return item;
        });
      }
    }

    const unremoveOrderRow = orderRow => {
      // unmark to remove
      // activeOrderPart.value.orderRows = activeOrderPart.value.orderRows.map(item => {
        viewData.orderRows = viewData.orderRows.map(item => {
        if (item.row_id === orderRow.row_id) {
          item.removeMe = false;
        }
        return item;
      });
    }

    const addNewOrderRow = orderRow => {
      const nextNewOrderRowId = getNextNewOrderId();
      orderRow.row_id = nextNewOrderRowId;
      // activeOrderPart.value.orderRows.push(orderRow);
      viewData.orderRows.push(orderRow);
      // focus on new order row
      orderRowIdToFocus.value = orderRow.row_id;
        // we have to switch back in order for the prop to retrigger if same id again
        setTimeout(() => {
          orderRowIdToFocus.value = 0;
      }, 1000);
    }

    const getNextNewOrderId = () => {
      // const currentMinNewOrderId = activeOrderPart.value.orderRows.reduce((result, orderRow) => {
      const currentMinNewOrderId = viewData.orderRows.reduce((result, orderRow) => {
        if (result > orderRow.row_id) {
          result = orderRow.row_id;
        }
        return result;
      }, 0);

      // return next value by decrementing currentMinNewOrderId
      return currentMinNewOrderId - 1;
    }

    const onAddTextRow = text => {
      const orderRow = {
        row_type: 'text',
        name: text,
      }
      addNewOrderRow(orderRow);
    }

    const onProductSelect = product => {
      const apiData = {
        prdId: product.v,
        cgrId: viewData.cgrId,
      }
      setOverlay(true, 'Hämtar produktdata');
      callApi({
        method: 'post',
        path: '/cms/orders/compose-order-row/',
        data: apiData,
      })
        .then((response) => {
          setOverlay();
          addNewOrderRow(response.orderRow);
        })
        .catch((err) => {
          setOverlay();
          console.log(err);
        });
    }

    const doCancelRestOrder = () => {
      showCancelRestOrder.value = false;
      setOverlay(true, 'Makulerar restorder');
      callApi({
        method: 'delete',
        path: `/cms/orders/cancel-last-fortnox-rest-order/${ohdId}/${viewData.orderEditHash}`,
      })
        .then(() => {
          onSave();
        })
        .catch((err) => {
          setOverlay();
          console.log(err);
        });
    }

    const getTotalOrderQty = () => {
      return viewData.orderRows.reduce((result, orderRow) => {
        const qty = viewData.isRestOrder ? orderRow.quantity - orderRow.quantity_delivered : orderRow.quantity;
        return result + qty;
      }, 0);
    }

    const updateOrder = () => {
      const errors = [];
      const formIsValid = form.value.validate();
      if (!formIsValid) {
        errors.push('Fyll i alla obligatoriska fält');
      }
      if (getTotalOrderQty() === 0) {
        if (viewData.isRestOrder) {
          errors.push('Minst en orderad måste ha ett restantal (om du vill makulera restordern finns det en röd knapp för det längst ner på sidan)');
        } else {
          errors.push('Minst en orderad måste ha antal (om du vill ta bort ordern använd makulerafunktionen på föregående sida)');
        }
      }
      if (errors.length) {
        showMessage(parseMessages(errors), true);
        return;
      }
      const apiData = {
        orderRows: viewData.orderRows,
        orderEditHash: viewData.orderEditHash,
      }

      setOverlay(true, 'Uppdaterar orderrader');
      callApi({
        method: 'put',
        path: `/cms/orders/order-edit/${ohdId}`,
        data: apiData,
      })
        .then((response) => {
          console.log("response", response);
          setOverlay();
          if (response.alerts.length) {
            // show alerts before return
            showAlert({
              text: parseMessages(response.alerts),
              onClose: onSave,
            });
          } else {
            // return
            onSave();
          }
        })
        .catch((err) => {
          setOverlay();
          console.log(err);
          // showMessage(err, null, 'putOrder');
        });
    }

    const onSave = () => {
      setOverlay(true);
      if (!store.getters.isFullLayoutMode) {
        tellKobra('on-update-order-rows', { ohdId });
      } else {
        goToDashboard();
      }
    }

    const goToDashboard = () => {
      router.replace({ name: 'orderDashboard' });
    }

    const onData = (response) => {
      console.log("response", response);
      viewData.orderRows = response.orderRows;
      viewData.hasFortnox = response.hasFortnox;
      viewData.fortnoxOrderNumber = response.fortnoxOrderNumber;
      viewData.cgrId = response.cgrId;
      viewData.isUndelivered = !!response.isUndelivered;
      viewData.isRestOrder = response.isRestOrder;
      viewData.orderEditHash = response.orderEditHash;
      viewData.showOptionColumn = response.showOptionColumn;
      viewData.priceTypeIsNetto = response.priceTypeIsNetto;
      searchableProduct_labelCols.value = response.searchableProductData.labelCols;
      searchableProduct_items.value = response.searchableProductData.items;
      dataLoaded.value = true;
    }

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: `/cms/orders/order-edit/${ohdId}`,
      })
        .then((apiResponse) => {
          onData(apiResponse);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    onMounted(doOnMounted);

    return {
      dataLoaded,
      saveInProgress,
      toolbarButtons,
      onToolbarButton,
      form,
      headline,
      searchableProduct_labelCols,
      searchableProduct_items,
      orderRowIdToFocus,
      removeOrderRow,
      unremoveOrderRow,
      viewData,
      onBackButton,
      orderRowMode,
      showLeaveWithoutSaving,
      showCancelRestOrder,
      doLeave,
      onProductSelect,
      onAddTextRow,
      doCancelRestOrder,
    }
  },
};
</script>

<style>
.v-data-table {
  background-color: transparent !important;
}
/* in order to have autocomplete suggestion div visible when inside table */
.v-data-table__wrapper {
  overflow: visible !important;
}
.vx-group-column div.v-slide-group__content {
  flex-direction: column !important;
}
</style>